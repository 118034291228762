/*NAME 
ADDRESS
PHONE_NUMBER
UNIT
-MONTAGE_DISTANCE
ADVANCE
PRICE
-MOUNTED
-PRODUCT_ORDERED
-MONTAGE_PLACE
-MONTAGE_DATE*/

import {
  Form,
  Button,
  Row,
  Col,
  Badge,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

export function ViewWesela() {
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [allYears, setAllYears] = useState([]);
  const [totalAdvances, setTotalAdvances] = useState(0);
  const [installations_arr, setInstallations_arr] = useState([]);
  const [shure_clients, setShure_clients] = useState(0);
  const [totalOrdersValue, setTotalOrdersValue] = useState(0);
  let date = "0000-00";

  const getData = async () => {
    try {
      if (Cookies.get("Rights") === "superadmin") {
        const response = await axios.get(
          `https://hyperion.srv22208.microhost.com.pl/get_financial_data.php?table=WESELA&year=${selectedYear}`
        );
        setTotalAdvances(response.data.ADVANCES);
        setTotalOrdersValue(response.data.ORDERS_VALUE);
      }
      if (
        Cookies.get("Rights") === "superadmin"
      ) {
        const response = await axios.get(
          `https://hyperion.srv22208.microhost.com.pl/get_wesela.php?year=${selectedYear}`
        );
        const years = await axios.get(
          `https://hyperion.srv22208.microhost.com.pl/get_years.php`
        );
        setAllYears(years.data);
        setData([]);
        console.log(response.data);
        setData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let shure_clients = 0;
    let installations = [];

    for (let i = 0; i < data.length; i++) {
      shure_clients++;
      installations.push(columnedView(i));
    }
    setInstallations_arr(installations);
    setShure_clients(shure_clients);
  }, [data]);

  const toggleYear = async (currentYear) => {
    let tempYear = currentYear;
    setSelectedYear(currentYear);
    if (Cookies.get("Rights") === "superadmin") {
      const response = await axios.get(
        `https://hyperion.srv22208.microhost.com.pl/get_financial_data.php?table=WESELA&year=${tempYear}`
      );
      setTotalAdvances(response.data.ADVANCES);
      setTotalOrdersValue(response.data.ORDERS_VALUE);
    }
    if (
      Cookies.get("Rights") === "superadmin"
    ) {
      await axios
        .get(
          `https://hyperion.srv22208.microhost.com.pl/get_wesela.php?year=${tempYear}`
        )
        .then((e) => {
          setData([]);
          setData(e.data);
        });
    }
  };

  const fill_texts_dict = {
    DATA_WESELA: {
      label: "Data wesela",
      placeholder: "",
      error_text: "",
      data_type: "DATE",
      value: "",
    },
    IMIE_PAN_MLODY: {
      label: "Pan mlody",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    IMIE_PANI_MLODA: {
      label: "Pani mloda",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    PRICE: {
      label: "Cena",
      placeholder: "",
      error_text: "",
      data_type: "REAL",
      value: 0,
    },
    ADVANCE: {
      label: "Zaliczka",
      placeholder: "",
      error_text: "",
      data_type: "REAL",
      value: 0,
    },
    ADRES_PAN_MLODY: {
      label: "Adres pan mlody",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    ADRES_PANI_MLODA: {
      label: "Adres pani mloda",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    NUMER_PAN_MLODY: {
      label: "Numer pan mlody",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    NUMER_PANI_MLODA: {
      label: "Numer pani mloda",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    NUMER_SWIADEK: {
      label: "Numer swiadek",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    NUMER_SWIADKOWA: {
      label: "Numer swiadkowa",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    KOSCIOL_GODZINA: {
      label: "Kosciol godzina",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    KOSCIOL_ADRES: {
      label: "Kosciol adres",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    DOM_WESELNY_GODZINA: {
      label: "Dom weselny godzina",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    DOM_WESELNY_ADRES: {
      label: "Dom weselny adres",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    ROZPOCZECIE_ZLECENIA: {
      label: "Rozpoczecie zlecenia",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    ZAKONCZENIE_ZLECENIA: {
      label: "Zakonczenie zlecenia",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    OPIS_ZLECENIA: {
      label: "Opis zlecenia",
      placeholder: "",
      error_text: "",
      data_type: "TEXT",
      value: "",
    },
    ADDED_DATE: {
      label: "Data dodania",
      placeholder: "",
      error_text: "",
      data_type: "DATE",
      value: "",
    },
  };

  const handleSaveEdited = async (installation_index) => {
    let installation = data[installation_index];
    let installation_id = data[installation_index].ID;


    if (installation.hasOwnProperty("ID")) delete installation["ID"];
    await axios
      .post(
        `https://hyperion.srv22208.microhost.com.pl/edit_wesele.php?id=` +
        installation_id,
        installation,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        getData();
      });
  };

  function handleDelete(installation_index) {
    let installation_id = data[installation_index].ID;
    axios
      .get(
        `https://hyperion.srv22208.microhost.com.pl/delete_wesele.php?id=` +
        installation_id
      )
      .then((res) => {
        getData();
      });
  }

  function FillTextElement(element_key, installation_index) {
    let control_by_type = (
      <Form.Control
        className="m-0"
        as="textarea"
        rows={1}
        type="text"
        defaultValue={data[installation_index][element_key]}
        placeholder={fill_texts_dict[element_key]["placeholder"]}
        onChange={(e) => {
          data[installation_index][element_key] = e.target.value;
        }}
      />
    );
    if (fill_texts_dict[element_key]["data_type"] === "REAL") {
      if (
        (element_key === "PRICE" || element_key === "ADVANCE") &&
        Cookies.get("Rights") === "admin"
      ) {
        control_by_type = (
          <Form.Control
            className="m-0"
            disabled
            type="number"
            step="0.1"
            min="0"
            max="999999999"
            defaultValue={data[installation_index][element_key]}
            placeholder={fill_texts_dict[element_key]["placeholder"]}
            onChange={(e) => {
              data[installation_index][element_key] = e.target.value;
            }}
          />
        );
      } else if (
        (element_key === "PRICE" || element_key === "ADVANCE") &&
        Cookies.get("Nick") === "administracja"
      ) {
        control_by_type = <></>;
      } else {
        control_by_type = (
          <Form.Control
            className="m-0"
            type="number"
            step="0.1"
            min="0"
            max="999999999"
            defaultValue={data[installation_index][element_key]}
            placeholder={fill_texts_dict[element_key]["placeholder"]}
            onChange={(e) => {
              data[installation_index][element_key] = e.target.value;
            }}
          />
        );
      }
    }
    if (fill_texts_dict[element_key]["data_type"] === "DATE") {
      console.log(fill_texts_dict[element_key])
      control_by_type = (
        <Form.Control
          className="m-0"
          type="date"
          defaultValue={data[installation_index][element_key]}
          placeholder={fill_texts_dict[element_key]["placeholder"]}
          onChange={(e) => {
            data[installation_index][element_key] = e.target.value;
          }}
        />
      );
      return (
        <>
          <Col className="m-0 col-6 col-md-3 col-lg-2">
            <Form.Group className="m-0" controlId={element_key}>
              <Form.Label className="m-0" size="sm">
                {fill_texts_dict[element_key]["label"]}
              </Form.Label>
              {control_by_type}
            </Form.Group>
          </Col>
        </>
      );
    } else
      return (
        <>
          <Col className="m-0 col-6 col-md-3 col-lg-2">
            <Form.Group className="m-0" controlId={element_key}>
              <Form.Label className="m-0" size="sm">
                {fill_texts_dict[element_key]["label"]}
              </Form.Label>
              {control_by_type}
            </Form.Group>
          </Col>
        </>
      );
  }


  let id_in_month = 0;
  function columnedView(installation_index) {
    console.log(fill_texts_dict)
    let elements = [];
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        elements.push(FillTextElement(key, installation_index));
      }
    }
    console.log(elements)
    function color_by_status() {
      if (
        parseFloat(data[installation_index].ADVANCE) >=
        parseFloat(data[installation_index].PRICE)
      )
        return { "background-color": "#8aff8a", border: "2px solid #000" };
      else if (
        parseFloat(data[installation_index].ADVANCE) <
        parseFloat(data[installation_index].PRICE) &&
        new Date(data[installation_index].DATA_WESELA) < new Date()
      )
        return { "background-color": "#ffca8a", border: "2px solid #000" };
      return { "background-color": "#fff", border: "2px solid #000" };
    }

    function idInMonth() {
      return ++id_in_month;
    }


    function date_plate() {
      function calculate_number_of_installations(sign_month, index) {
        let number_of_installation_in_month = 0;
        let current_sign_month = sign_month;
        for (let i = index; i < data.length; i++) {
          if (current_sign_month === sign_month) {
            if (i + 1 < data.length)
              if (
                current_sign_month === "jutro" ||
                current_sign_month === "dziś"
              )
                current_sign_month = "nieokreślono";
              else current_sign_month = data[i + 1].DATA_WESELA.substring(0, 7);
            else current_sign_month = "0000-00";

            number_of_installation_in_month++;
          } else break;
        }
        return number_of_installation_in_month;
      }
      if (data[installation_index].DATA_WESELA !== undefined) {
        let sign_month = data[installation_index].DATA_WESELA.substring(0, 7);
        if (date !== sign_month) {
          if (sign_month === "jutro" || sign_month === "dziś")
            sign_month = "nieokreślono";
          date = sign_month;
          id_in_month = 0;
          let num = calculate_number_of_installations(
            sign_month,
            installation_index
          );
          return (
            <h2>
              <Badge variant="success">{sign_month}</Badge>{" "}
              <Badge variant="success">Ilość w miesiącu {num}</Badge>
            </h2>
          );
        }
      }
      return <div></div>;
    }
    return (
      <>
        {date_plate()}
        <div style={color_by_status()}>
          <div style={{ margin: "-2px 2px 0px 2px", padding: "5px" }}>
            <Form className="m-0">
              <Row>
                <Col className="m-0">
                  <h3>
                    <Badge className="mt-0" variant="info">
                      {idInMonth()}
                    </Badge>{" "}
                    <Badge className="mt-0" variant="info">
                      {data[installation_index].IMIE_PAN_MLODY} --{" "}
                      {data[installation_index].IMIE_PANI_MLODA}
                    </Badge>
                    {Cookies.get("Rights") !== "readonly" ? (
                      <Button
                        className="mx-1"
                        variant="primary"
                        size="sm"
                        onClick={() => handleSaveEdited(installation_index)}
                      >
                        Zapisz
                      </Button>
                    ) : (
                      <></>
                    )}
                    {Cookies.get("Rights") === "superadmin" ? (
                      <Button
                        className="mr-3"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Czy na pewno chcesz usunąć instalacje " +
                              (installation_index + 1) +
                              " ?"
                            )
                          )
                            handleDelete(installation_index);
                        }}
                      >
                        Usuń
                      </Button>
                    ) : (
                      <></>
                    )}

                  </h3>
                </Col>

              </Row>

              <Row className="m-0">{elements.map((elem) => elem)}</Row>
            </Form>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />

      <div className="d-flex mx-2 flex-row flex-wrap">
        <h2>
          <Badge variant="success">
            Ilość pewnych klientów {shure_clients}
          </Badge>
        </h2>
        <DropdownButton
          className="ml-5 mt-1"
          id="dropdown-basic-button"
          title={selectedYear}
        >
          {allYears &&
            allYears.map((element) => {
              return (
                <Dropdown.Item
                  style={{ minWidth: "unset" }}
                  key={`yearId_${element}`}
                  onClick={() => {
                    toggleYear(element);
                  }}
                >
                  {element}
                </Dropdown.Item>
              );
            })}
        </DropdownButton>
        {Cookies.get("Rights") === "superadmin" ? (
          <div className="d-flex flex-row">
            <h2 className="mx-2">
              <Badge variant="success">
                Wartość zamówień: {Math.round(totalOrdersValue)} zł
              </Badge>{" "}
            </h2>
            <h2 className="mx-2">
              <Badge variant="success">
                Zaliczki: {Math.round(totalAdvances)} zł
              </Badge>{" "}
            </h2>
            <h2 className="mx-2">
              <Badge variant="danger">
                Brakuje: {Math.round(totalOrdersValue - totalAdvances)} zł
              </Badge>{" "}
            </h2>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {installations_arr.map((elem) => elem)}
      </div>
    </>
  );
}

export default withRouter(ViewWesela);
