import { Form, Button, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export function AddWesele() {
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substr(0, 10);

    const [fill_texts_dict, setFillTextsDict] = useState({
        DATA_WESELA: {
            label: "Data wesela",
            placeholder: "",
            error_text: "",
            data_type: "DATE",
            value: date,
        },
        IMIE_PAN_MLODY: {
            label: "Pan mlody",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        IMIE_PANI_MLODA: {
            label: "Pani mloda",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        PRICE: {
            label: "Cena",
            placeholder: "",
            error_text: "",
            data_type: "REAL",
            value: 0,
        },
        ADVANCE: {
            label: "Zaliczka",
            placeholder: "",
            error_text: "",
            data_type: "REAL",
            value: 0,
        },
        ADRES_PAN_MLODY: {
            label: "Adres pan mlody",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        ADRES_PANI_MLODA: {
            label: "Adres pani mloda",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        NUMER_PAN_MLODY: {
            label: "Numer pan mlody",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        NUMER_PANI_MLODA: {
            label: "Numer pani mloda",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        NUMER_SWIADEK: {
            label: "Numer swiadek",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        NUMER_SWIADKOWA: {
            label: "Numer swiadkowa",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        KOSCIOL_GODZINA: {
            label: "Kosciol godzina",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        KOSCIOL_ADRES: {
            label: "Kosciol adres",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        DOM_WESELNY_GODZINA: {
            label: "Dom weselny godzina",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        DOM_WESELNY_ADRES: {
            label: "Dom weselny adres",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        ROZPOCZECIE_ZLECENIA: {
            label: "Rozpoczecie zlecenia",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        ZAKONCZENIE_ZLECENIA: {
            label: "Zakonczenie zlecenia",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        OPIS_ZLECENIA: {
            label: "Opis zlecenia",
            placeholder: "",
            error_text: "",
            data_type: "TEXT",
            value: "",
        },
        ADDED_DATE: {
            label: "Data dodania",
            placeholder: "",
            error_text: "",
            data_type: "DATE",
            value: date,
        },
    });

    let handleSubmit = (event) => {
        event.preventDefault();
        let json = {};
        for (var key in fill_texts_dict) {
            if (fill_texts_dict.hasOwnProperty(key)) {
                json[key] = fill_texts_dict[key]["value"];
            }
        }

        AddToDatabase();
    };

    function AddToDatabase() {
        let json = {};
        for (var key in fill_texts_dict) {
            if (fill_texts_dict.hasOwnProperty(key)) {
                json[key] = fill_texts_dict[key]["value"];
            }
        }

        console.log(json);

        axios
            .post(
                `https://hyperion.srv22208.microhost.com.pl/add_wesele.php`,
                json,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                window.location.reload(false);
            });
    }
    function FillTextElement(element_key) {
        let control_by_type = (
            <Form.Control
                type="text"
                placeholder={fill_texts_dict[element_key]["placeholder"]}
                defaultValue=" "
                onChange={(e) => {
                    fill_texts_dict[element_key]["value"] = e.target.value;
                }}
            />
        );
        if (fill_texts_dict[element_key]["data_type"] === "REAL") {
            control_by_type = (
                <Form.Control
                    type="number"
                    step="0.1"
                    min="0"
                    max="999999999"
                    defaultValue="0"
                    placeholder={fill_texts_dict[element_key]["placeholder"]}
                    onChange={(e) => {
                        fill_texts_dict[element_key]["value"] = e.target.value;
                    }}
                />
            );
        } else if (
            (element_key === "PRICE" || element_key === "ADVANCE") &&
            Cookies.get("Nick") === "administracja"
        ) {
            control_by_type = <></>;
        } else if (fill_texts_dict[element_key]["data_type"] === "DATE") {
            if (element_key === "ADDED_DATE") {
                var curr = new Date();
                curr.setDate(curr.getDate());
                var date = curr.toISOString().substr(0, 10);
                control_by_type = (
                    <Form.Control
                        type="date"
                        defaultValue={date}
                        placeholder={fill_texts_dict[element_key]["placeholder"]}
                        onChange={(e) => {
                            fill_texts_dict[element_key]["value"] = e.target.value;
                        }}
                    />
                );
            } else
                control_by_type = (
                    <Form.Control
                        type="date"
                        placeholder={fill_texts_dict[element_key]["placeholder"]}
                        onChange={(e) => {
                            fill_texts_dict[element_key]["value"] = e.target.value;
                        }}
                    />
                );
        }

        return (
            <>
                <Form.Group controlId={element_key}>
                    <Form.Label>{fill_texts_dict[element_key]["label"]}</Form.Label>
                    {control_by_type}
                    <Form.Text className="text-muted">
                        {fill_texts_dict[element_key]["error_text"]}
                    </Form.Text>
                </Form.Group>
            </>
        );
    }



    let elements = [];
    for (var key in fill_texts_dict) {
        if (fill_texts_dict.hasOwnProperty(key)) {
            elements.push(FillTextElement(key));
        }
    }

    if (
        Cookies.get("Rights") !== "" &&
        Cookies.get("Rights") !== null &&
        Cookies.get("Rights") !== "readonly"
    ) {
        return (
            <>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                    integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                    crossorigin="anonymous"
                />
                <div className="px-md-5 pt-md-4">
                    <Form>
                        <Row>
                            {elements.map((elem) => (
                                <Col className="m-0 col-12 col-md-6 col-lg-4"> {elem} </Col>
                            ))}
                        </Row>

                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                            Dodaj
                        </Button>
                    </Form>

                </div>
            </>
        );
    } else {
        return <></>;
    }
}

export default withRouter(AddWesele);
